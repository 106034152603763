export function mergeByProperty(target, source, prop) {
  source.forEach(sourceElement => {
    const targetElement = target.find(targetElement => {
      return sourceElement[prop] === targetElement[prop]
    })
    targetElement ? Object.assign(targetElement, sourceElement) : target.push(sourceElement)
  })
}

export function uniqueArray(array) {
  return array.filter((item, index) => (array.indexOf(item) === index))
}

export function removeItemsFromArray(array, items) {
  return array.filter((item) => !items.includes(item))
}

export function convertToHalfWidth(value) {
  return value.replace(/[\uFF0D\uFF70\u30FC\u2212]/g, "-").replace(/[\uFF10-\uFF19]/g, (s) => String.fromCharCode(s.charCodeAt(0) - 0xfee0));
}

export function validateForm(formId) {
  const valid = $(formId).validate().checkForm();
  const submitButton = $(formId).find('button[type="submit"]');

  if (valid) {
    submitButton.prop("disabled", false);
    submitButton.removeClass("disabled");
  } else {
    submitButton.prop("disabled", "disabled");
    submitButton.addClass("disabled");
  }
}

export function getQuestionsList(data, questionCodes) {
  const questions = data
    .filter(question => questionCodes.includes(question.code))
    .sort((a, b) => a.position - b.position);

  return questions
}

export function getQuestion(data, questionCode) {
  const question = data.find(question => question.code === questionCode)

  return question
}

export function getNgQuestionsList(questions, ngQuestionIds, type = null) {
  const result = []

  questions.forEach(question => {
    if (type !== null) {
      if (type === "basic_condition") {
        if (question.hasOwnProperty("is_foreigner_job")) {
          return
        }
      } else if (type === "foreigner_job") {
        if (!question.hasOwnProperty("is_foreigner_job")) {
          return
        }
      }
    }

    if (ngQuestionIds.includes(question.id)) {
      const description = question.ng_description ? question.ng_description : question.description
      result.push(description)
    }

    if (question.sub_questions) {
      question.sub_questions.forEach(subQuestion => {
        if (ngQuestionIds.includes(subQuestion.id)) {
          result.push(subQuestion.description)
        }
      })
    }
  })

  return result
}

export function getAnswer(data, questionId) {
  const answer = data.find(answerObj => answerObj.question_id === questionId)

  return answer ? answer.answer : null
}

export function buildFormInitialValues(question, answers = []) {
  const values = {}

  if (question) {
    if (question.sub_questions) {
      question.sub_questions.forEach(question => {
        const questionId = question.id
        let answer = getAnswer(answers, questionId)
        if (!answer) {
          answer = question.answer
        }
        values[`answer_${question.id}`] = answer
      });
    } else {
      values[`answer_${question.id}`] = getAnswer(answers, question.id) || question.answer
    }
  }

  return values
}

export function getRadioAnswers(formValues) {
  const answers = []

  for (const key in formValues) {
    const answer = formValues[key]

    answers.push({
      question_id: parseInt(key.split("_")[1]),
      answer
    })
  }

  return answers
}

export function getCheckboxAnswers(questionId, formValues) {
  const answers = []
  const valuesObj = Object.values(formValues)[0]

  for (const key in valuesObj) {
    if (valuesObj[key] === true) {
      answers.push(key)
    }
  }

  return {
    question_id: questionId,
    answer: answers.join(",")
  }
}


export function getCheckboxAnswers2(formValues) {
  const questionId = parseInt(Object.keys(formValues)[0].split("_")[1])
  const answers = []
  const valuesObj = Object.values(formValues)[0]

  for (const key in valuesObj) {
    if (valuesObj[key] === true) {
      answers.push(key)
    }
  }

  return {
    question_id: questionId,
    answer: answers.join(",")
  }
}

export const redirectToTop = () => {
  window.location.href = "/"
}

export const flattenArray = (array) => {
  return Array.prototype.concat.apply([], array)
}
